import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
  },
})

export default class Footer extends Vue {
  currentYear: number = new Date().getFullYear();

  /**
   * Get copyright statement. Use 'en dash'
   * for separating ranges of numbers, not
   * a regular dash.
   *
   * @see http://www.thepunctuationguide.com/en-dash.html
   * @returns {string}
   */
  get copyrightStatement(): string {
    if (this.currentYear === 2019) {
      return this.currentYear.toString();
    }

    return `2019–${this.currentYear.toString()}`;
  }
}
